import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Empty, Toast, Dialog, Loading,Button, Popup, Collapse, CollapseItem } from 'vant'
import { setTimeout } from 'core-js'
import { Calendar } from 'vant'
import { Cascader } from 'vant'
import { Uploader } from 'vant'
import areaList from '../../utils/area1'

Vue.use(Button);
Vue.use(Uploader)
Vue.use(Cascader)
Vue.use(Calendar)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Popup)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Empty)
Vue.use(Toast)
export default {
  components: {},
  data () {
    return {
      id: '',
      disable: true,
      length: '0',
      problem: '',
      before: true,
      after: false,
      riqi: '',
      adress: '',
      hosp: '',
      room: '',
      yicard: '',
      doorone: '',
      title: "",
      dateshow: false,
      minDate: new Date(2021, 9, 1),
      maxDate: new Date(2021, 11, 31),
      startTime: '',//开始时间
      endTime: '',//结束时间
      // 地区弹框
      options: [],
      showArea: false,
      fieldValue: '',
      cascaderValue: '',
      fileList: [],
      fileList1: [],
      surefileList: '',
      surefileList1: '',
      isloading:false,



    }
  },
  created () {
    this.id = this.$route.query.id
    this.title = this.$route.query.name
    this.options = areaList.area
    this.begin = this.$route.query.begin
    this.end = this.$route.query.end
    console.log(this.end)
    this.minDate = new Date(this.begin.split('-')[0], parseInt(this.begin.split('-')[1]) - 1, this.begin.split('-')[2])
    this.maxDate = new Date(this.end.split('-')[0], parseInt(this.end.split('-')[1]) - 1, this.end.split('-')[2])

  },
  updated () {
    this.length = this.problem.length
    if (this.problem == '' || this.startTime == '' || this.adress == '' || this.hosp == '' || this.room == '') {
      this.disable = true
    } else {
      this.disable = false
    }
  },
  methods: {
    ...mapActions('followUpAppointment', ['subApply']),
    // 图片上传

    onFinish ({ selectedOptions }) {
      this.showArea = false
      this.adress = selectedOptions.map((option) => option.text).join('/')
    },
    showDate () {
      this.dateshow = true

    },
    formatDate (date) {
      console.log(date)
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    onConfirm (date) {
      const [start, end] = date
      this.dateshow = false
      var date = `${this.formatDate(start)} -${this.formatDate(end)}`
      console.log(date)
      this.startTime = this.formatDate(start)//开始时间
      this.endTime = this.formatDate(end)//结束时间

    },

    back () {
      this.$router.go(-1)

    },
    toSub () {
      var that = this
      this.disable = true
      this.isloading=true
      var data = {
        yhUserEquityId: that.id,// （用户权益数据唯一标识）
        applyHospitalStartDate: that.startTime,//（就医开始日期）
        applyHospitalEndDate: that.endTime,//（就医结束日期）
        applyHospital: that.hosp,// （申请医院）
        address: that.adress,//（医院地址）
        applyDepartment: that.room,//（申请科室）
        illnessNote: that.problem,//（病情描述/诉求描述）

      }
      this.subApply(data).then(res2 => {
        this.disable = false
        this.isloading=false
        console.log(res2)
        if(res2.code==200){
          this.$router.push({ name: 'message', query: { name: this.title, fId: res2.flowId ,theid:this.id} })

        }else{
          Toast(res2.msg)
        }


      })







    }

  },
}
